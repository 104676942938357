import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Icon } from "@iconify/react";

import SharpeLogoWhite from "../images/sharpe-logo-white.svg";
import SharpeLogoBlack from "../images/sharpe-logo-black.svg";

import { getSharpePointsForUser } from "../firebase/config";
import ShimmerButton from "./magicui/shimmer-button";
import { ChevronDownIcon } from "lucide-react";
// import { useRouter } from 'next/router'
import GlobalSearch from "./GlobalSearch";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { NavMenu } from "./NavMenu";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme, mode }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     color: mode === "light" ? "#000" : "#98ECFF",
//     border: "1px solid #98ECFF",
//     background: mode === "light" ? "#98ECFF" : "transparent",
//     padding: "8px 16px",
//     fontSize: "14px",
//     cursor: "default",
//     fontFamily: "Inter, sans-serif",
//     "&:hover": {
//       border: "1px solid #98ECFF",
//     },
//     textTransform: "capitalize",
//     borderRadius: "0",
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: mode === "light" ? "#98ECFF" : "#98ECFF",
//   },
// }));

export default function Header({ onModeChange, walletAddress }) {
  const [mode, setMode] = useState("dark");
  const [sharpePoints, setSharpePoints] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  // const isSmallScreen = useIsSmallScreen(600)
  const mainWebsiteApiUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PUBLIC_API_URL
      : process.env.REACT_APP_PUBLIC_LOCAL_API_URL;

  const handleSearchInputClick = () => {
    setOpenSearch(true);
  };

  // Function to close search (received from child)
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  console.log("walletAddress", walletAddress);
  // Function to toggle mode
  const toggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    onModeChange(newMode);
  };

  useEffect(() => {
    const fetchSharpePoints = async () => {
      try {
        const points = await getSharpePointsForUser(walletAddress);
        console.log("points", points);
        setSharpePoints(points);
      } catch (error) {
        console.error("Error fetching Sharpe points:", error);
      }
    };

    if (walletAddress) {
      fetchSharpePoints();
    }
  }, [walletAddress]);

  const renderIcon = () => {
    if (mode === "light") {
      return (
        <Icon
          icon="tdesign:mode-dark"
          width="22"
          height="22"
          style={{ color: "rgb(74 74 74)" }}
        />
      );
    } else {
      return (
        <Icon
          icon="iconamoon:mode-light"
          width="22"
          height="22"
          style={{ color: "#cfcfcf" }}
        />
      );
    }
  };
  const icon = renderIcon();

  const handleKeydown = useCallback(
    (event) => {
      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (
        !openSearch &&
        event.which === 191 &&
        (event.ctrlKey || event.metaKey)
      ) {
        setOpenSearch(true);
      }
    },
    [openSearch]
  );

  const handleKeyUp = useCallback(
    (event) => {
      // ** ESC key to close searchbox
      if (openSearch && event.keyCode === 27) {
        setOpenSearch(false);
      }
    },
    [openSearch]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp, handleKeydown]);

  return (
    <div className="flex items-center w-full justify-between py-[25px] px-[20px] 2xl:px-[40px]">
      <div className="flex items-center flex-1">
        <a href="https://sharpe.ai/" style={{ textDecoration: "none" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: "100%", width: "110px" }}
              src={mode === "light" ? SharpeLogoBlack : SharpeLogoWhite}
              alt="profile"
            />
          </Box>
        </a>
        {/* <div
          class="hidden lg:flex max-w-md mx-auto"
          onClick={handleSearchInputClick}
        >
          <div class="relative flex items-center cursor-pointer border border-[#27272a] px-2 py-0 w-full h-10 rounded-[8px] focus-within:shadow-lg bg-transparent overflow-hidden">
            <div className="flex items-center">
              <div class="grid place-items-center h-full w-12 text-neutral-300">
                <Icon
                  icon="fluent:search-16-regular"
                  width="22"
                  height="22"
                  style={{ color: "#fff", cursor: "pointer" }}
                />
              </div>
              <span class="peer h-full w-full outline-none bg-transparent text-sm text-neutral-400 pr-2 pl-1">
                Search
              </span>
              <p className="text-sm flex items-center gap-1 text-neutral-500">
                Press{" "}
                <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded  bg-[#27272A] px-1.5 font-mono text-[10px] font-medium text-neutral-300 opacity-100">
                  <span className="text-md">⌘</span>/
                </kbd>
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <NavMenu
        apiUrl={mainWebsiteApiUrl}
        customClasses={{
          container: "custom-container-class",
          navSection: {
            button: "custom-button-class",
          },
        }}
      />

      <div
        style={{ display: "flex", gap: "8px", alignItems: "center", flex: 1 }}
      >
        {/* <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild className="bg-transparent rounded-md">
              <Button
                variant="outline"
                size="icon"
                className={`
        bg-transparent 
        rounded-[6px] 
        h-[44px]
        w-[44px]
        hover:bg-transparent 
        ${mode === "dark" ? "border-neutral-700" : "border-neutral-400"} 
        ${mode === "dark" ? "dark:bg-black" : ""}
      `}
                onClick={toggleMode}
              >
                {icon}
              </Button>
            </TooltipTrigger>
            <TooltipContent
              className={`border-neutral-800 rounded-[4px] bg-transparent ${
                mode === "dark" ? "border-neutral-800" : "border-neutral-200"
              } ${mode === "dark" ? "text-neutral-200" : "text-black"}`}
            >
              <p
                className={`text-[12px]
               ${mode === "dark" ? "text-white" : "text-black"},`}
              >
                Switch to {mode === "light" ? "dark" : "light"} mode
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> */}
        <div className="flex flex-1 items-center justify-end">
          <div
            className="hidden lg:flex w-52 mr-4 ml-4"
            onClick={handleSearchInputClick}
          >
            <label htmlFor="search" className="sr-only">
              Search assets
            </label>
            <div className="relative w-full">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                id="search"
                name="search"
                className="block w-full rounded-[8px] cursor-pointer border-0 bg-white/5 py-2.5 pl-10 pr-3 text-white placeholder:text-gray-400 outline-none focus:ring-0 caret-transparent sm:text-sm sm:leading-6"
                placeholder="Search assets"
                type="search"
                autoComplete="off"
              />

              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <kbd className="inline-flex items-center rounded px-1 font-sans text-xs text-gray-400">
                  ⌘K
                </kbd>
              </div>
            </div>
          </div>
          <a
            href="https://stake.sharpe.ai"
            className="rounded-[8px] whitespace-nowrap bg-[#2470FF] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#5792FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2470FF]"
          >
            Earn $SAI
          </a>
        </div>
      </div>
      <GlobalSearch openSearch={openSearch} handleClose={handleCloseSearch} />
    </div>
  );
}
